<template>
    <div class="card-body" v-if="activityLog.length">
        <!-- List group -->
        <div class="list-group list-group-flush list-group-activity my-n3">
            <template v-for="item in activityLog">
                <!-- <zs-activity-invite-created
                    v-if="(item.Type == ACTIVITY_TYPE_INVITE_CREATED) || (item.Type == ACTIVITY_TYPE_ASSIGNED_ROLE_LM) || (item.Type == ACTIVITY_TYPE_ASSIGNED_ROLE_KM) || (item.Type == ACTIVITY_TYPE_ASSIGNED_ROLE_INSTALLER) || (item.Type == ACTIVITY_TYPE_ASSIGNED_ROLE_CM) || (item.Type == ACTIVITY_TYPE_ASSIGNED_ROLE_CO)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                /> -->
                <zs-activity-invite-created
                    v-if="(item.Type == ACTIVITY_TYPE_INVITE_CREATED)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-assigned-role
                    v-if="(item.Type == ACTIVITY_TYPE_ASSIGNED_ROLE_LM) || (item.Type == ACTIVITY_TYPE_ASSIGNED_ROLE_KM) || (item.Type == ACTIVITY_TYPE_ASSIGNED_ROLE_INSTALLER)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-invite-accepted
                    v-if="(item.Type == ACTIVITY_TYPE_INVITE_ACCEPTED)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-location-created
                    v-if="(item.Type == ACTIVITY_TYPE_LOCATION_CREATED)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-location-updated
                    v-if="(item.Type == ACTIVITY_TYPE_LOCATION_UPDATED)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-location-deleted
                    v-if="(item.Type == ACTIVITY_TYPE_LOCATION_DELETED)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-key-deleted
                    v-if="(item.Type == ACTIVITY_TYPE_KEY_DELETED)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-key-created
                    v-if="(item.Type == ACTIVITY_TYPE_KEY_CREATED)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-key-updated
                    v-if="(item.Type == ACTIVITY_TYPE_KEY_UPDATED)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-key-access-updated
                    v-if="(item.Type === ACTIVITY_TYPE_KEY_ACCESS_UPDATED)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-salto-online-device-deleted
                    v-if="(item.Type == ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_DELETED)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-salto-online-device-updated
                    v-if="(item.Type == ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_UPDATED)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-salto-online-device-created
                    v-if="(item.Type == ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_CREATED)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-salto-installation-deleted
                    v-if="(item.Type == ACTIVITY_TYPE_SALTO_INSTALLATION_DELETED)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-salto-installation-updated
                    v-if="(item.Type == ACTIVITY_TYPE_SALTO_INSTALLATION_UPDATED)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-salto-installation-created
                    v-if="(item.Type == ACTIVITY_TYPE_SALTO_INSTALLATION_CREATED)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-device-deleted
                    v-if="(item.Type == ACTIVITY_TYPE_DEVICE_DELETED)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-device-updated
                    v-if="(item.Type == ACTIVITY_TYPE_DEVICE_UPDATED)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-device-created
                    v-if="item.Type == ACTIVITY_TYPE_DEVICE_CREATED"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-device-locked
                    v-if="item.Type == ACTIVITY_TYPE_DEVICE_LOCKED"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-device-unlocked
                    v-if="item.Type == ACTIVITY_TYPE_DEVICE_UNLOCKED"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-device-unlocked-dialpad-fail
                    v-if="item.Type == ACTIVITY_TYPE_DEVICE_UNLOCKED_DIALPAD_FAIL"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-device-unlocked-dialpad-success
                    v-if="item.Type == ACTIVITY_TYPE_DEVICE_UNLOCKED_DIALPAD_SUCCESS"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-device-lock-fail
                    v-if="item.Type == ACTIVITY_TYPE_DEVICE_LOCK_FAIL"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-device-unlock-fail
                    v-if="item.Type == ACTIVITY_TYPE_DEVICE_UNLOCK_FAIL"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <!-- <zs-activity-device-locked
                    v-if="item.Type == ACTIVITY_TYPE_DEVICE_LOCKED"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-device-unlocked
                    v-if="item.Type == ACTIVITY_TYPE_DEVICE_UNLOCKED"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-device-locked-user
                    v-if="(item.Type == ACTIVITY_TYPE_DEVICE_LOCKED_USER)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-device-unlocked-user
                    v-if="(item.Type == ACTIVITY_TYPE_DEVICE_UNLOCKED_USER)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                /> -->
                <zs-activity-user-removed-from-key
                    v-if="(item.Type == ACTIVITY_TYPE_USER_REMOVED_FROM_KEY)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-user-removed-from-staff
                    v-if="(item.Type == ACTIVITY_TYPE_REMOVED_STAFF)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-user-removed-from-location
                    v-if="(item.Type == ACTIVITY_TYPE_USER_REMOVED_FROM_LOCATION)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-removed-role-company-manager
                    v-if="(item.Type == ACTIVITY_TYPE_REMOVED_ROLE_COMPANY_MANAGER)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-removed-role-location-manager
                    v-if="(item.Type == ACTIVITY_TYPE_REMOVED_ROLE_LOCATION_MANAGER)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-removed-role-key-manager
                    v-if="(item.Type == ACTIVITY_TYPE_REMOVED_ROLE_KEY_MANAGER)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-removed-role-installer
                    v-if="(item.Type == ACTIVITY_TYPE_REMOVED_ROLE_INSTALLER)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-devices-connected
                    v-if="(item.Type == ACTIVITY_TYPE_CONNECT_DEVICES)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-devices-disconnected
                    v-if="(item.Type == ACTIVITY_TYPE_DISCONNECT_DEVICES)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
            </template>
        </div>
    </div>
    <div class="card-body" v-else>
        <empty-list :title="$t('activity.no_activity')" />
    </div>
</template>

<script>
import ActivityInviteCreated from "@/components/activity/ActivityInviteCreated";
import ActivityAssignedRole from "@/components/activity/ActivityAssignedRole";
import ActivityInviteAccepted from "@/components/activity/ActivityInviteAccepted";
import ActivityLocationCreated from "@/components/activity/ActivityLocationCreated";
import ActivityLocationUpdated from "@/components/activity/ActivityLocationUpdated";
import ActivityLocationDeleted from "@/components/activity/ActivityLocationDeleted";
import ActivityKeyDeleted from "@/components/activity/ActivityKeyDeleted";
import ActivityKeyCreated from "@/components/activity/ActivityKeyCreated";
import ActivityKeyUpdated from "@/components/activity/ActivityKeyUpdated";
import ActivityKeyAccessUpdated from "@/components/activity/ActivityKeyAccessUpdated";
import ActivityDeviceDeleted from "@/components/activity/ActivityDeviceDeleted";
import ActivityDeviceUpdated from "@/components/activity/ActivityDeviceUpdated";
import ActivityDeviceCreated from "@/components/activity/ActivityDeviceCreated";
import ActivityDeviceLocked from "@/components/activity/ActivityDeviceLocked";
import ActivityDeviceUnlocked from "@/components/activity/ActivityDeviceUnlocked";
import ActivityDeviceLockedUser from "@/components/activity/ActivityDeviceLockedUser";
import ActivityDeviceUnlockedUser from "@/components/activity/ActivityDeviceUnlockedUser";
import ActivityUserRemovedFromKey from "@/components/activity/ActivityUserRemovedFromKey";
import ActivityUserRemovedFromLocation from "@/components/activity/ActivityUserRemovedFromLocation"
import ActivityRemovedRoleCompanyManager from "@/components/activity/ActivityRemovedRoleCompanyManager";
import ActivityRemovedRoleLocationManager from "@/components/activity/ActivityRemovedRoleLocationManager";
import ActivityRemovedRoleKeyManager from "@/components/activity/ActivityRemovedRoleKeyManager";
import ActivityRemovedRoleInstaller from "@/components/activity/ActivityRemovedRoleInstaller";
import ActivityDeviceLockedFail from "@/components/activity/ActivityDeviceLockedFail";
import ActivityDeviceUnlockedFailed from "@/components/activity/ActivityDeviceUnlockedFailed";
import ActivityDeviceUnlockedDialpadFail from "@/components/activity/ActivityDeviceUnlockedDialpadFail";
import ActivityDeviceUnlockedDialpadSuccess from "@/components/activity/ActivityDeviceUnlockedDialpadSuccess";
import ActivityRemovedStaff from "@/components/activity/ActivityRemovedStaff";
import ActivitySaltoOnlineDeviceDeleted from "@/components/activity/ActivitySaltoOnlineDeviceDeleted";
import ActivitySaltoOnlineDeviceUpdated from "@/components/activity/ActivitySaltoOnlineDeviceUpdated";
import ActivitySaltoOnlineDeviceCreated from "@/components/activity/ActivitySaltoOnlineDeviceCreated";
import ActivitySaltoInstallationCreated from "@/components/activity/ActivitySaltoInstallationCreated";
import ActivitySaltoInstallationUpdated from "@/components/activity/ActivitySaltoInstallationUpdated";
import ActivitySaltoInstallationDeleted from "@/components/activity/ActivitySaltoInstallationDeleted";
import ActivityDevicesConnected from "@/components/activity/ActivityDevicesConnected";
import ActivityDevicesDisconnected from "@/components/activity/ActivityDevicesDisconnected";
import EmptyList from "@/components/common/EmptyList";

import {
    ACTIVITY_TYPE_ROLE_SET,
    ACTIVITY_TYPE_KEY_CREATED,
    ACTIVITY_TYPE_KEY_DELETED,
    ACTIVITY_TYPE_KEY_UPDATED,
    ACTIVITY_TYPE_DEVICE_UPDATED,
    ACTIVITY_TYPE_DEVICE_DELETED,
    ACTIVITY_TYPE_DEVICE_CREATED,
    ACTIVITY_TYPE_LOCATION_CREATED,
    ACTIVITY_TYPE_LOCATION_UPDATED,
    ACTIVITY_TYPE_LOCATION_DELETED,
    ACTIVITY_TYPE_INVITE_CREATED,
    ACTIVITY_TYPE_ASSIGNED_ROLE_CO,
    ACTIVITY_TYPE_ASSIGNED_ROLE_CM,
    ACTIVITY_TYPE_ASSIGNED_ROLE_LM,
    ACTIVITY_TYPE_ASSIGNED_ROLE_KM,
    ACTIVITY_TYPE_ASSIGNED_ROLE_INSTALLER,
    ACTIVITY_TYPE_INVITE_ACCEPTED,
    ACTIVITY_TYPE_DEVICE_LOCKED,
    ACTIVITY_TYPE_DEVICE_UNLOCKED,
    // ACTIVITY_TYPE_DEVICE_LOCKED_USER,
    // ACTIVITY_TYPE_DEVICE_UNLOCKED_USER,
    ACTIVITY_TYPE_USER_REMOVED_FROM_KEY,
    ACTIVITY_TYPE_USER_REMOVED_FROM_LOCATION,
    ACTIVITY_TYPE_COMPANY_CREATED,
    ACTIVITY_TYPE_COMPANY_UPDATED,
    ACTIVITY_TYPE_COMPANY_DELETED,
    ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_CREATED,
    ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_DELETED,
    ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_UPDATED,
    ACTIVITY_TYPE_REMOVED_ROLE_COMPANY_MANAGER,
    ACTIVITY_TYPE_REMOVED_ROLE_LOCATION_MANAGER,
    ACTIVITY_TYPE_REMOVED_ROLE_KEY_MANAGER,
    ACTIVITY_TYPE_REMOVED_ROLE_INSTALLER,
    ACTIVITY_TYPE_DEVICE_LOCK_FAIL,
    ACTIVITY_TYPE_DEVICE_UNLOCK_FAIL,
    ACTIVITY_TYPE_DEVICE_UNLOCKED_DIALPAD_FAIL,
    ACTIVITY_TYPE_DEVICE_UNLOCKED_DIALPAD_SUCCESS,
    ACTIVITY_TYPE_REMOVED_STAFF,
    ACTIVITY_TYPE_CONNECT_DEVICES,
    ACTIVITY_TYPE_DISCONNECT_DEVICES,
    ACTIVITY_TYPE_SALTO_INSTALLATION_DELETED,
    ACTIVITY_TYPE_SALTO_INSTALLATION_UPDATED,
    ACTIVITY_TYPE_SALTO_INSTALLATION_CREATED, ACTIVITY_TYPE_KEY_ACCESS_UPDATED

} from "@/handlers/const";

export default {
    data() {
        return {
            ACTIVITY_TYPE_ROLE_SET: ACTIVITY_TYPE_ROLE_SET,
            ACTIVITY_TYPE_KEY_CREATED: ACTIVITY_TYPE_KEY_CREATED,
            ACTIVITY_TYPE_KEY_DELETED: ACTIVITY_TYPE_KEY_DELETED,
            ACTIVITY_TYPE_KEY_UPDATED: ACTIVITY_TYPE_KEY_UPDATED,
            ACTIVITY_TYPE_KEY_ACCESS_UPDATED: ACTIVITY_TYPE_KEY_ACCESS_UPDATED,
            ACTIVITY_TYPE_DEVICE_UPDATED: ACTIVITY_TYPE_DEVICE_UPDATED,
            ACTIVITY_TYPE_INVITE_CREATED: ACTIVITY_TYPE_INVITE_CREATED,
            ACTIVITY_TYPE_ASSIGNED_ROLE_CO: ACTIVITY_TYPE_ASSIGNED_ROLE_CO,
            ACTIVITY_TYPE_ASSIGNED_ROLE_CM: ACTIVITY_TYPE_ASSIGNED_ROLE_CM,
            ACTIVITY_TYPE_ASSIGNED_ROLE_LM: ACTIVITY_TYPE_ASSIGNED_ROLE_LM,
            ACTIVITY_TYPE_ASSIGNED_ROLE_KM: ACTIVITY_TYPE_ASSIGNED_ROLE_KM,
            ACTIVITY_TYPE_ASSIGNED_ROLE_INSTALLER: ACTIVITY_TYPE_ASSIGNED_ROLE_INSTALLER,
            ACTIVITY_TYPE_INVITE_ACCEPTED: ACTIVITY_TYPE_INVITE_ACCEPTED,
            ACTIVITY_TYPE_LOCATION_CREATED: ACTIVITY_TYPE_LOCATION_CREATED,
            ACTIVITY_TYPE_LOCATION_UPDATED: ACTIVITY_TYPE_LOCATION_UPDATED,
            ACTIVITY_TYPE_LOCATION_DELETED: ACTIVITY_TYPE_LOCATION_DELETED,
            ACTIVITY_TYPE_DEVICE_DELETED: ACTIVITY_TYPE_DEVICE_DELETED,
            ACTIVITY_TYPE_DEVICE_CREATED: ACTIVITY_TYPE_DEVICE_CREATED,
            ACTIVITY_TYPE_DEVICE_LOCKED: ACTIVITY_TYPE_DEVICE_LOCKED,
            ACTIVITY_TYPE_DEVICE_UNLOCKED: ACTIVITY_TYPE_DEVICE_UNLOCKED,
           /*  ACTIVITY_TYPE_DEVICE_LOCKED_USER: ACTIVITY_TYPE_DEVICE_LOCKED_USER,
            ACTIVITY_TYPE_DEVICE_UNLOCKED_USER: ACTIVITY_TYPE_DEVICE_UNLOCKED_USER, */
            ACTIVITY_TYPE_USER_REMOVED_FROM_KEY: ACTIVITY_TYPE_USER_REMOVED_FROM_KEY,
            ACTIVITY_TYPE_USER_REMOVED_FROM_LOCATION: ACTIVITY_TYPE_USER_REMOVED_FROM_LOCATION,
            ACTIVITY_TYPE_COMPANY_CREATED: ACTIVITY_TYPE_COMPANY_CREATED,
            ACTIVITY_TYPE_COMPANY_UPDATED: ACTIVITY_TYPE_COMPANY_UPDATED,
            ACTIVITY_TYPE_COMPANY_DELETED: ACTIVITY_TYPE_COMPANY_DELETED,
            ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_CREATED: ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_CREATED,
            ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_DELETED: ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_DELETED,
            ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_UPDATED: ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_UPDATED,
            ACTIVITY_TYPE_SALTO_INSTALLATION_DELETED: ACTIVITY_TYPE_SALTO_INSTALLATION_DELETED,
            ACTIVITY_TYPE_SALTO_INSTALLATION_UPDATED: ACTIVITY_TYPE_SALTO_INSTALLATION_UPDATED,
            ACTIVITY_TYPE_SALTO_INSTALLATION_CREATED: ACTIVITY_TYPE_SALTO_INSTALLATION_CREATED,
            ACTIVITY_TYPE_REMOVED_ROLE_COMPANY_MANAGER: ACTIVITY_TYPE_REMOVED_ROLE_COMPANY_MANAGER,
            ACTIVITY_TYPE_REMOVED_ROLE_LOCATION_MANAGER: ACTIVITY_TYPE_REMOVED_ROLE_LOCATION_MANAGER,
            ACTIVITY_TYPE_REMOVED_ROLE_KEY_MANAGER: ACTIVITY_TYPE_REMOVED_ROLE_KEY_MANAGER,
            ACTIVITY_TYPE_REMOVED_ROLE_INSTALLER: ACTIVITY_TYPE_REMOVED_ROLE_INSTALLER,
            ACTIVITY_TYPE_DEVICE_LOCK_FAIL: ACTIVITY_TYPE_DEVICE_LOCK_FAIL,
            ACTIVITY_TYPE_DEVICE_UNLOCK_FAIL: ACTIVITY_TYPE_DEVICE_UNLOCK_FAIL,
            ACTIVITY_TYPE_DEVICE_UNLOCKED_DIALPAD_FAIL: ACTIVITY_TYPE_DEVICE_UNLOCKED_DIALPAD_FAIL,
            ACTIVITY_TYPE_DEVICE_UNLOCKED_DIALPAD_SUCCESS: ACTIVITY_TYPE_DEVICE_UNLOCKED_DIALPAD_SUCCESS,
            ACTIVITY_TYPE_REMOVED_STAFF: ACTIVITY_TYPE_REMOVED_STAFF,
            ACTIVITY_TYPE_CONNECT_DEVICES: ACTIVITY_TYPE_CONNECT_DEVICES,
            ACTIVITY_TYPE_DISCONNECT_DEVICES: ACTIVITY_TYPE_DISCONNECT_DEVICES,
            adminUser: false,
        };
    },
    props: {
        activityLog: Array,
    },
    created(){
        this.adminUser = this.$session.get("adminUser");
    },
    methods: {},
    components: {
        "zs-activity-invite-created": ActivityInviteCreated,
        "zs-activity-assigned-role": ActivityAssignedRole,
        "zs-activity-invite-accepted": ActivityInviteAccepted,
        "zs-activity-location-created": ActivityLocationCreated,
        "zs-activity-location-updated": ActivityLocationUpdated,
        "zs-activity-location-deleted": ActivityLocationDeleted,
        "zs-activity-key-deleted": ActivityKeyDeleted,
        "zs-activity-key-created": ActivityKeyCreated,
        "zs-activity-key-updated": ActivityKeyUpdated,
        "zs-activity-key-access-updated": ActivityKeyAccessUpdated,
        "zs-activity-device-deleted": ActivityDeviceDeleted,
        "zs-activity-device-updated": ActivityDeviceUpdated,
        "zs-activity-device-created": ActivityDeviceCreated,
        "zs-activity-device-locked": ActivityDeviceLocked,
        "zs-activity-device-unlocked": ActivityDeviceUnlocked,
        // "zs-activity-device-locked-user": ActivityDeviceLockedUser,
        // "zs-activity-device-unlocked-user": ActivityDeviceUnlockedUser,
        "zs-activity-user-removed-from-key": ActivityUserRemovedFromKey,
        "zs-activity-user-removed-from-location": ActivityUserRemovedFromLocation,
        "zs-activity-removed-role-company-manager": ActivityRemovedRoleCompanyManager,
        "zs-activity-removed-role-location-manager": ActivityRemovedRoleLocationManager,
        "zs-activity-removed-role-key-manager": ActivityRemovedRoleKeyManager,
        "zs-activity-removed-role-installer": ActivityRemovedRoleInstaller,
        "zs-activity-device-lock-fail": ActivityDeviceLockedFail,
        "zs-activity-device-unlock-fail": ActivityDeviceUnlockedFailed,
        "zs-activity-salto-online-device-deleted": ActivitySaltoOnlineDeviceDeleted,
        "zs-activity-salto-online-device-updated": ActivitySaltoOnlineDeviceUpdated,
        "zs-activity-salto-online-device-created": ActivitySaltoOnlineDeviceCreated,
        "zs-activity-salto-installation-created": ActivitySaltoInstallationCreated,
        "zs-activity-salto-installation-updated": ActivitySaltoInstallationUpdated,
        "zs-activity-salto-installation-deleted": ActivitySaltoInstallationDeleted,
        "zs-activity-device-unlocked-dialpad-fail": ActivityDeviceUnlockedDialpadFail,
        "zs-activity-device-unlocked-dialpad-success": ActivityDeviceUnlockedDialpadSuccess,
        "zs-activity-user-removed-from-staff":  ActivityRemovedStaff,
        "zs-activity-devices-connected": ActivityDevicesConnected,
        "zs-activity-devices-disconnected":  ActivityDevicesDisconnected,
        "empty-list": EmptyList,
    },
};
</script>
<style scoped>
.list-group-item:last-child{
  overflow-y: hidden !important;
}
</style>
