<template>
  <div class="row justify-content-center">
    <b-col cols="12">
      <div class="header">
        <div class="container-fluid">
          <!-- Body -->
          <div class="header-body">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="header-pretitle">
                  {{ $t("locations.location") }}
                </h6>
                <h1 class="header-title">
                  {{ $t("activity.title") }}
                </h1>
              </div>
            </div>
            <div class="row align-items-center" v-if="activityAccessEnabled && false">
              <div class="col">
                <!-- Nav -->
                <b-nav tabs class="nav-overflow header-tabs">
                  <b-nav-item :active="mode == 0" @click="setMode(0)">
                    {{ $t("activity.all_log") }}
                  </b-nav-item>
                  <b-nav-item :active="mode == 1" @click="setMode(1)">
                    {{ $t("activity.events_log") }}
                  </b-nav-item>
                  <b-nav-item :active="mode == 2" @click="setMode(2)">
                    {{ $t("activity.admin_log") }}
                  </b-nav-item>
                </b-nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <div class="tab-content"
                 v-if="activityAccessEnabled"
            >
              <div class="card">
                <div class="card-header">
                  <div class="row align-items-center">
                    <div class="col">
                      <div v-if="exportState === EXPORT_STATE_NONE()">
                        <b-button
                          size="sm"
                          @click="exportActivity"
                          class="btn-white"
                        >
                          <b-icon
                            icon="download"
                            aria-hidden="true"
                            class="mr-2"
                          ></b-icon>

                          {{ $t("buttons.export") }}
                        </b-button>
                      </div>
                      <div v-else-if="exportState === EXPORT_STATE_WORKING()" class="d-inline-block">
                        <b-spinner
                          small
                          :disabled="true"
                          class="mr-2"
                        ></b-spinner>
                        {{ $t("activity.exporting") }}

                        <b-link @click="cancelExport" style="margin-left:20px;vertical-align: middle;">
                          <span  style="font-size: 180%;">&times;</span>
                        </b-link>
                      </div>
                      <div v-else-if="exportState === EXPORT_STATE_ERROR()" class="d-inline-block">
                        <b-badge variant="danger" style="font-size: 1.1em;">
                          {{$t("activity.error_label") + exportError}}
                        </b-badge>

                        <b-link @click="cancelExport" style="margin-left:20px;vertical-align: middle;">
                          <span  style="font-size: 180%;">&times;</span>
                        </b-link>
                      </div>
                      <div v-else class="d-inline-block" >
                        <b-link ref="exportFileLink" :href="exportLink" :download="getExportFileName()" target="_blank">{{$t("activity.download_again")}}</b-link>
                        <b-link @click="cancelExport" style="margin-left:20px;vertical-align: middle;">
                          <span  style="font-size: 180%;">&times;</span>
                        </b-link>
                      </div>
                    </div>
                    <div class="col-auto mr-n3">
                      <!-- Select -->
                      <form>
                        <Select2
                          v-model="perPage"
                          :options="perPageOptions"
                          :settings="{
                            minimumResultsForSearch: -1,
                            containerCssClass:
                              'custom-select custom-select-sm form-control-flush',
                            dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                          }"
                          @change="initActivity"
                        />
                      </form>
                    </div>
                    <div class="col-auto">
                      <!-- Dropdown -->
                      <b-button
                        v-if="false"
                        class="btn-sm btn-white"
                        @click="filterStatus(showFilters = !showFilters)"
                      >
                        <i class="fe fe-sliders mr-1"></i>
                        {{ $t("common.filters") }}
                      </b-button>
                    </div>
                  </div>
                  <!-- / .row -->
                </div>
                <div class="card-body b-border">
                  <div class="list-group list-group-flush mt-n4">
                    <div class="list-group-item" style="overflow: hidden;">
                      <div class="row">
                        <div class="col">
                          <small>{{ $t("common.range") }}</small>
                        </div>
                        <div class="col-4 mr-n3">
                          <b-form-select
                            size="sm"
                            :value="selectedRange"
                            @change="setRange"
                          >
                            <b-form-select-option
                              class="text-left text-truncate"
                              v-for="range in ranges"
                              :value="range"
                              v-bind:key="range.index"
                              >{{ $t(range.name) }}</b-form-select-option
                            >
                          </b-form-select>
                        </div>
                        <div class="col-4">
                         <!--  v-if="selectedRange.index == 0" -->
                          <flat-pickr
                            v-model="flatpickrRange"
                            :config="{
                              mode: 'range',
                              locale: $i18n.locale == 'sv'
                                            ? locale
                                            : 'default',
                            }"
                            :maxDate="maxDate"
                            class="form-control form-control-sm borderColorChange"
                            @on-close="initActivityRange"
                            :disabled="selectedRange.index != 0"
                          ></flat-pickr>
                        </div>
                      </div>
                      <!-- / .row -->
                    </div>
                    <div class="list-group-item" style="overflow: hidden;"  v-if="showFilters">
                      <div class="row">
                        <div class="col">
                          <small>{{ $t("common.filter") }}</small>
                        </div>
                        <div class="col-4 mr-n3">
                          <b-form-select
                            size="sm"
                            :value="selectedFilter"
                            @change="setFilter"
                          >
                            <b-form-select-option
                              class="text-left text-truncate"
                              v-for="filter in filters"
                              v-bind:key="filter.key"
                              :value="filter"
                              >{{ $t(filter.name) }}</b-form-select-option
                            >
                          </b-form-select>
                        </div>
                        <div class="col-4" v-if="isMobile || (this.width<700)">
                          <Select2
                            v-model="selectedFilterValue"
                            class="select-dropdown"
                            :options="filterValuesList"
                            :disabled="!activityFilterBoxDisable"
                            v-if="activityFilterBoxDisable"
                            :settings="{
                              containerCssClass:
                                'custom-select custom-select-sm form-control form-control-sm borderColorChange',
                              dropdownCssClass:
                                'dropdown-menu dropdown-menu-sm dropdown-menu-addition',
                            }"
                            @change="applyFilter"
                          />
                          <!-- :disabled="filterValuesList.length == 0" -->
                        </div>
                        <div class="col-4" v-else>
                          <Select2
                            v-model="selectedFilterValue"
                            class="select-dropdown"
                            :options="filterValuesList"
                            :disabled="!activityFilterBoxDisable"
                            v-if="activityFilterBoxDisable"
                            :settings="{
                              containerCssClass:
                                'custom-select custom-select-sm form-control form-control-sm borderColorChange',
                              dropdownCssClass:
                                'dropdown-menu dropdown-menu-sm',
                            }"
                            @change="applyFilter"
                          />
                          <!-- :disabled="filterValuesList.length == 0" -->
                        </div>
                      </div>
                      <!-- / .row -->
                    </div>
                  </div>
                </div>
        <!-- <div class="card-header">
          !-- Form --
          <form>
            <div class="input-group input-group-flush input-group-merge">
             !-- Input --

              <b-form-input
                :placeholder="$t('common.search')"
                v-model="filterActivity"
                class="form-control form-control-prepended search"
                type="search"
              ></b-form-input>

              !-- Prepend --
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <span class="fe fe-search"></span>
                </div>
              </div>
            </div>
          </form>
        </div> -->
                <b-col cols="12">
                  <!-- v-if="loading" -->
                  <!-- <div
                    class="d-flex justify-content-center text-center my-3"
                    v-if="activityFlag == true && !paginatedActivityList.length"
                  >
                    <b-spinner :label="$t('common.loading')"></b-spinner>
                  </div> -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    aria-controls="my-table"
                    class="pb-2"
                  ></b-pagination>
                  <div v-if="loading" class="d-flex justify-content-center text-center my-3">
                    <b-spinner :label="$t('common.loading')"></b-spinner>
                  </div>
                  <div  v-else-if="activity.length">
                    <zs-activity-main-timeline :activityLog="paginatedActivityList" />
                  </div>
                  <div v-else>
                    <empty-list :title="$t('activity.no_activity')" />
                  </div>
                <!-- <b-col cols="12" class="px-0" v-if="activity.length && !loading">
                  <zs-activity-main-timeline :activityLog="paginatedActivityList" />

                </b-col>
                <b-col
                  cols="12"
                  variant="light"
                  v-else-if="!activity.length && !loading"
                  ><empty-list :title="$t('activity.no_activity')" />
                </b-col> -->
                <!-- v-if="activity.length"  :total-rows="activity.length" -->
                  <b-pagination
                          v-if="!loading"
                          v-model="currentPage"
                          :total-rows="totalRows"
                          :per-page="perPage"
                          aria-controls="my-table"
                          class="pb-2"
                  ></b-pagination>
                </b-col>
              </div>
            </div>
            <div v-else>
                <br/>
                <h1>{{$t('activity.access_denied')}}</h1>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-col>
  </div>
</template>

<script>
import { Swedish } from "flatpickr/dist/l10n/sv.js";
import flatPickr from "vue-flatpickr-component";
import Select2 from "@/components/common/Select2";

import MainTimeline from "@/components/activity/MainTimeline";
import activityMixin from "@/mixins/activityMixin";
import EmptyList from "@/components/common/EmptyList";
import { mapGetters } from "vuex";

import { KEYS_GET } from "@/store/types/keys";
import { DEVICES_GET } from "@/store/types/devices";
import { LOCATION_COMPANY_USERS_GET, LOCATION_MEMBERS_GET } from "@/store/types/members";

import {
  ACTIVITY_LOG_LOCATION,
  activityEventTypes,
  activityAdminTypes,
  activityAllTypes,
  ACTIVITY_TYPE_ROLE_SET,
  ACTIVITY_TYPE_KEY_CREATED,
  ACTIVITY_TYPE_KEY_DELETED,
  ACTIVITY_TYPE_KEY_UPDATED,
  ACTIVITY_TYPE_DEVICE_UPDATED,
  ACTIVITY_TYPE_DEVICE_DELETED,
  ACTIVITY_TYPE_DEVICE_CREATED,
  ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_CREATED,
  ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_DELETED,
  ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_UPDATED,
  ACTIVITY_TYPE_LOCATION_CREATED,
  ACTIVITY_TYPE_LOCATION_UPDATED,
  ACTIVITY_TYPE_INVITE_CREATED,
  ACTIVITY_TYPE_ASSIGNED_ROLE_LM,
  ACTIVITY_TYPE_ASSIGNED_ROLE_KM,
  ACTIVITY_TYPE_ASSIGNED_ROLE_INSTALLER,
  ACTIVITY_TYPE_INVITE_ACCEPTED,
  ACTIVITY_TYPE_DEVICE_LOCKED,
  ACTIVITY_TYPE_DEVICE_UNLOCKED,
  ACTIVITY_TYPE_DEVICE_LOCKED_USER,
  ACTIVITY_TYPE_DEVICE_UNLOCKED_USER,
  ACTIVITY_TYPE_DEVICE_UNLOCKED_DIALPAD_SUCCESS,
  ACTIVITY_TYPE_DEVICE_UNLOCKED_DIALPAD_FAIL,
  ACTIVITY_TYPE_DEVICE_LOCK_FAIL,
  ACTIVITY_TYPE_DEVICE_UNLOCK_FAIL,
  ACTIVITY_TYPE_USER_REMOVED_FROM_KEY,
  ACTIVITY_TYPE_USER_REMOVED_FROM_LOCATION,
  ACTIVITY_TYPE_REMOVED_ROLE_COMPANY_MANAGER,
  ACTIVITY_TYPE_REMOVED_STAFF,
  ACTIVITY_TYPE_REMOVED_ROLE_LOCATION_MANAGER,
  ACTIVITY_TYPE_REMOVED_ROLE_KEY_MANAGER,
  ACTIVITY_TYPE_REMOVED_ROLE_INSTALLER,
  ACTIVITY_TYPE_CONNECT_DEVICES,
  ACTIVITY_TYPE_DISCONNECT_DEVICES,
  ACTIVITY_TYPE_SALTO_INSTALLATION_CREATED,
  ACTIVITY_LOG_LOCATION_PG,
  EXPORT_STATE_NONE, EXPORT_STATE_ERROR, EXPORT_STATE_DONE, EXPORT_STATE_WORKING, ACTIVITY_LOG_EXPORT
} from "@/handlers/const";

import {
  ROLE_COMPANY_MANAGER,
  ROLE_LOCATION_MANAGER,
  ROLE_KEY_MANAGER,
  ROLE_DEVICE_INSTALLER,
  ROLE_KEY_PERMANENT,
  ROLE_KEY_TEMPORARY,
  ROLE_COMPANY_OWNER,
} from "@/acl/roles";

import aggregatedController from "@/api/aggregated";
import { sleep } from "@/store/modules/global";
import activityController from "@/api/activity";

export default {
  mixins: [activityMixin],
  data() {
    return {
      activity: [],
      mode: 0,
      perPage: 20,
      currentPage: 1,
      isMobile: false,
      width: window.innerWidth,
      height: window.innerHeight,
      activityFilterBoxDisable: false,
      activityFlag: true,
      // locale: this.$i18n.locale == "sv" ? "sv" : "default",
      filterActivity: "",
      locale: Swedish,
      showFilters: true,
      perPageOptions: [
        { id: 10, text: "10 " + this.$t("menu.per_page") },
        { id: 20, text: "20 " + this.$t("menu.per_page") },
        { id: 50, text: "50 " + this.$t("menu.per_page") },
      ],
      lastRange: [
          this.$moment().subtract(7, "days").toDate(),
          this.$moment().toDate(),
      ],
      flatpickrRange: [
          this.$moment().subtract(7, "days").toDate(),
          this.$moment().toDate(),
      ],
      selectedRange: {
        value: [
          this.$moment().subtract(7, "days").toDate(),
          this.$moment().toDate(),
        ],
        name: "activity.range_custom",
        changeable: true,
        index: 0,
      },
      ranges: [
        {
          value: [
            // this.$moment().subtract(90, "days").toDate(),
            this.$moment().subtract(7, "days").toDate(),
            this.$moment().toDate(),
          ],
          name: "activity.range_custom",
          changeable: true,
          index: 0,
        },
        {
          value: [
            this.$moment().subtract(7, "days").toDate(),
            this.$moment().toDate(),
          ],
          name: "activity.range_7_days",
          changeable: false,
          index: 1,
        },
        {
          value: [
            this.$moment().subtract(30, "days").toDate(),
            this.$moment().toDate(),
          ],
          name: "activity.range_30_days",
          changeable: false,
          index: 2,
        },
        {
          value: [
            this.$moment().subtract(90, "days").toDate(),
            this.$moment().toDate(),
          ],
          name: "activity.range_90_days",
          changeable: false,
          index: 3,
        },
        {
          value: [
            this.$moment().subtract(365, "days").toDate(),
            this.$moment().toDate(),
          ],
          name: "activity.range_year",
          changeable: false,
          index: 4,
        },
        /* {
          value: [
            this.$moment().startOf("week").toDate(),
            this.$moment().toDate(),
          ],
          name: "activity.range_week",
          changeable: false,
          index: 3,
        },
        {
          value: [
            this.$moment().startOf("month").toDate(),
            this.$moment().toDate(),
          ],
          name: "activity.range_month",
          changeable: false,
          index: 4,
        }, */
      ],
      maxDate: new Date(),
      exporting: false,
      exportState: EXPORT_STATE_NONE,
      exportError: "",
      exportLink: "",
      exportInterval: null,
      selectedFilter: { name: "activity.filter_all", key: "all" },
      filters: [
        {
          name: "activity.filter_all",
          key: "all",
        },
        {
          name: "activity.filter_phone",
          key: "PhoneNumber",
        },
        {
          name: "activity.filter_user",
          key: "UserId",
        },
        {
          name: "activity.filter_key",
          key: "KeyId",
        },
        {
          name: "activity.filter_door",
          key: "DeviceId",
        },
      ],
      // selectedFilterValue: null,
      selectedFilterValue: '',
      filter: '',
    };
  },
  computed: {
    ...mapGetters([
        "getLocationDevices",
        "getLocationKeys",
        "getLocationMembers",
        "getLocationCompanyUsers",
        "getLocationActivity",
        "getInitProcessing",
        "getActiveUserRole",
    ]),
    activityAccessEnabled() {
      return this.getActiveUserRole == "CompanyOwner" || this.getActiveUserRole == "CompanyManager" || this.getActiveUserRole == "LocationManager" || this.getActiveUserRole == "KeyManager";
    },
    filterValuesList() {
      const defaultValue = {
        text: this.$t("activity.filter_all"),
        id: 0,
      };
      switch (this.selectedFilter.key) {
        case "KeyId": {
          return [
            defaultValue,
            ...this.getLocationKeys
            .filter((item) =>
                item.Name
                .toLowerCase()
                .includes(this.filter.toLowerCase())
            )
            .sort((i1, i2) => {
                    return i1.Name.toUpperCase() > i2.Name.toUpperCase() ? 1 : -1;
            })
            .map((item) => {
              if(item.Name.includes("Key of: ")){
                item.Name = item.Name.replace("Key of: ","");
              }
              return { text: item.Name, id: item.Id };
            }),
          ];
        }
        case "DeviceId": {
          return [
            defaultValue,
            ...this.getLocationDevices
            .filter((item) =>
                item.Device.Name
                .toLowerCase()
                .includes(this.filter.toLowerCase())
            )
            .sort((i1, i2) => {
                    return i1.Device.Name.toUpperCase() > i2.Device.Name.toUpperCase() ? 1 : -1;
            })
            .map((item) => {
              return {
                text: item.Device.Name,
                id: item.Device.Id,
              };
            }),
          ];
        }
        case "UserId": {
          return [
            defaultValue,
            ...(this.getLocationMembers
              .filter(
                (item, index, self) =>
                  self.findIndex((t) => t.User.Id === item.User.Id) === index
              )
              .sort((i1, i2) => {
                  return i1.User.FirstName?.toUpperCase() > i2.User.FirstName?.toUpperCase() ? 1 : -1;
              })
              .map((item) => {
                if(item.User.FirstName === null)
                  item.User.FirstName = ''

                if(item.User.LastName === null){
                  item.User.LastName = ''
                  return {
                    text: item.User.FirstName + " " + item.User.LastName,
                    id: item.User.Id,
                  };
                }else{
                  return {
                    text: item.User.FirstName + " " + item.User.LastName,
                    id: item.User.Id,
                  };
                }
              })
              .concat(
                  (this.getLocationCompanyUsers == null) ? [] :
                      this.getLocationCompanyUsers
                          .map((item) => {
                              if(item.User.FirstName === null)
                                  item.User.FirstName = ''

                              if(item.User.LastName === null)
                                item.User.LastName = ''
                              return {
                                  text: item.User.FirstName + " " + item.User.LastName,
                                  id: item.User.Id,
                              };
                          })
              )
              .filter(
                  (item, index, self) =>
                      self.findIndex((t) => t.id === item.id) === index
              )
            ),
          ];
        }
        case "PhoneNumber": {
          return [
            defaultValue,
            ...(this.getLocationMembers
              .filter(
                (item, index, self) =>
                  self.findIndex((t) => t.User.Id === item.User.Id) === index
              )
              .map((item) => {
                return {
                  text: item.User.PhoneNumber,
                  id: item.User.Id,
                };
              })
              .concat(
                    (this.getLocationCompanyUsers == null) ? [] :
                        this.getLocationCompanyUsers
                            .map((item) => {
                                if(item.User.LastName === null)
                                    item.User.LastName = ''
                                return {
                                    text: item.User.PhoneNumber,
                                    id: item.User.Id,
                                };
                            })
                )
                .filter(
                    (item, index, self) =>
                        self.findIndex((t) => t.id === item.id) === index
                )
            ),
          ];
        }
        default:
          return [];
      }
    },
    activityList(){
      return this.activity
        .filter((item) =>{
          let filterActivityVal = true;
          if(this.selectedFilterValue != ''){
            // console.log("this.selectedFilterValue:"+this.selectedFilterValue)
            if(this.selectedFilterValue == 0){
               filterActivityVal = item.Name.toLowerCase().includes(this.filterActivity.toLowerCase())
            }else{
              filterActivityVal = item.KeyId == this.selectedFilterValue || item.DeviceId == this.selectedFilterValue || item.LocationId == this.selectedFilterValue || item.CompanyId == this.selectedFilterValue || item.User.Id == this.selectedFilterValue
              if(this.filterActivity != ''){
                filterActivityVal = (item.KeyId == this.selectedFilterValue || item.DeviceId == this.selectedFilterValue || item.LocationId == this.selectedFilterValue || item.CompanyId == this.selectedFilterValue || item.User.Id == this.selectedFilterValue) && (item.Name.toLowerCase().includes(this.filterActivity.toLowerCase()))

                /*if(item.Company != null){
                  filterActivityVal = item.Company.Name.toLowerCase().includes(this.filterActivity.toLowerCase())
                }else if(item.Location != null){
                  filterActivityVal = item.Location.Name.toLowerCase().includes(this.filterActivity.toLowerCase())
                }else */
                if(item.Device != null){
                  filterActivityVal = item.Device.Name.toLowerCase().includes(this.filterActivity.toLowerCase())
                }else if(item.Key != null){
                  filterActivityVal = item.Key.Name.toLowerCase().includes(this.filterActivity.toLowerCase())
                }else if(item.User != null){
                  filterActivityVal = item.User.Name.toLowerCase().includes(this.filterActivity.toLowerCase()) // || item.User.LastName.toLowerCase().includes(this.filterActivity.toLowerCase())
                }else{
                  filterActivityVal = item.Name.toLowerCase().includes(this.filterActivity.toLowerCase())
                }
              }
            }
          }else{
            filterActivityVal = item.Name.toLowerCase().includes(this.filterActivity.toLowerCase())

            if(this.filterActivity != ''){
              /* if(item.Company != null){
                filterActivityVal = item.Company.Name.toLowerCase().includes(this.filterActivity.toLowerCase())
              }else if(item.Location != null){
                filterActivityVal = item.Location.Name.toLowerCase().includes(this.filterActivity.toLowerCase())
              }else */
              if(item.Device != null){
                filterActivityVal = item.Device.Name.toLowerCase().includes(this.filterActivity.toLowerCase())
              }else if(item.Key != null){
                filterActivityVal = item.Key.Name.toLowerCase().includes(this.filterActivity.toLowerCase())
              }else if(item.User != null){
                filterActivityVal = item.User.Name.toLowerCase().includes(this.filterActivity.toLowerCase()) // || item.User.LastName.toLowerCase().includes(this.filterActivity.toLowerCase())
              }else{
                filterActivityVal = item.Name.toLowerCase().includes(this.filterActivity.toLowerCase())
              }
            }
          }

          // return filterActivityVal
          if(filterActivityVal == true){
            this.activityFlag = true;
            return filterActivityVal
          }else{
            this.activityFlag = false;
            return
          }
        })
        .sort((i1, i2) => {
           return i1.Time < i2.Time
              ? 1
              : -1;
        });
    },
    paginatedActivityList() {
        if (this.currentPage == this.currPage + 1) {
            return this.activity
        }
        else if (this.currentPage == this.nextPage + 1) {
            return this.activityNext
        }
        else {
            if (this.currentPage == 1)
                this.initActivity()
            else
                this.loadActivityPage(this.currentPage - 1)

            return this.activity
        }
    },
  },
  async created() {
      this.initFilters();

      // wait for init completed
      for (let i = 0; i < 30; i++) {
          await sleep(100)
          if (this.getInitProcessing === false) {
              break
          }
      }

      this.initActivity();
      window.addEventListener("resize", this.onResize);
      window.addEventListener("onload", this.onLoad);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
    this.isMobile = false;
  },
  methods: {
    EXPORT_STATE_ERROR() {
      return EXPORT_STATE_ERROR
    },
    EXPORT_STATE_WORKING() {
      return EXPORT_STATE_WORKING
    },
    EXPORT_STATE_NONE() {
      return EXPORT_STATE_NONE
    },
    cancelExport() {
      this.exportState = EXPORT_STATE_NONE
      this.exportError = ""
      this.exportLink = ""

      if (this.exportInterval) {
        clearInterval(this.exportInterval)
        this.exportInterval = null
      }
    },
    setExportError(error) {
      this.exportState = EXPORT_STATE_ERROR
      this.exportError = error

      if (this.exportInterval) {
        clearInterval(this.exportInterval)
        this.exportInterval = null
      }
    },
    filterStatus(status){
      if(!status){
        this.selectedFilterValue = '';
        // this.selectedFilter.key = "all"
        this.activityFilterBoxDisable = false;
        this.selectedFilter = { name: "activity.filter_all", key: "all" };
        this.selectedRange.index = 0;
        this.selectedRange.name = "activity.range_custom";
        /* this.selectedRange = {
          value: [
            this.$moment().subtract(7, "days").toDate(),
            this.$moment().toDate(),
          ],
          name: "activity.range_custom",
          changeable: true,
          index: 0,
        }; */
        this.filterVal = '';
      }
    },
    initActivityRange(range) {
      this.selectedRange.value = range;
      this.initActivity();
    },
    initActivity() {
        this.clearExportActivity()
        this.loadActivityPage(0)
    },
    loadActivityPage(pageNum) {
        this.activity = [];
        const params = this.buildActivityParams()
        if (params && pageNum)
            params.page = pageNum

        this.loadActivity(params, ACTIVITY_LOG_LOCATION);

        if (params?.page === 1 || !this.totalRows)
            this.loadActivityPg(params, ACTIVITY_LOG_LOCATION_PG);
    },
    onResize(e) {
        this.width = window.innerWidth;
        this.height = window.innerHeight;
    },
    onLoad(evt){
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.isMobile = true
      } else if(typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1){
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    },

    buildActivityParams() {
      let mainParams = {
        locationId: this.$route.params.locationId,
        page: 0,
        limit: this.perPage,
        IgnorePagination: false,
        EventTypes: this.getActivityTypes(),
        StartDate: this.$moment(this.selectedRange.value[0])
            .startOf("day")
            .toISOString(),
        EndDate: this.$moment(this.selectedRange.value[1])
          .endOf("day")
          .toISOString(),
      };
      if (this.selectedFilterValue) {
        switch (this.selectedFilter.key) {
          case "KeyId": {
            if(this.selectedFilterValue === '0'){
              mainParams.KeyId = '';
            }else{
              mainParams.KeyId = this.selectedFilterValue;
            }
            break;
          }
          case "DeviceId": {
            if(this.selectedFilterValue === '0'){
              mainParams.DeviceId = '';
            }else{
              mainParams.DeviceId = this.selectedFilterValue;
            }
            break;
          }
          case "UserId": {
            if(this.selectedFilterValue === '0'){
              mainParams.UserId = '';
            }else{
              mainParams.UserId = this.selectedFilterValue;
            }
            break;
          }
          case "PhoneNumber": {
            if(this.selectedFilterValue === '0'){
              mainParams.UserId = '';
            }else{
              mainParams.UserId = this.selectedFilterValue;
            }
            break;
          }
          default:
            break;
        }
      }
      return mainParams;
    },

    initFilters() {
      this.$store.dispatch(DEVICES_GET, this.$route.params.locationId);
      this.$store.dispatch(KEYS_GET, this.$route.params.locationId);
      this.$store.dispatch(LOCATION_MEMBERS_GET, this.$route.params.locationId);
      this.$store.dispatch(LOCATION_COMPANY_USERS_GET, this.$route.params.locationId);
    },

    setMode(mode) {
      this.mode = mode;
      this.initActivity();
    },

    setRange(range) {
      this.selectedRange = range;
      if(range.name == "activity.range_7_days"){
        this.flatpickrRange = [
          this.$moment().subtract(7, "days").toDate(),
          this.$moment().toDate(),
        ]
      }else if(range.name == "activity.range_30_days"){
        this.flatpickrRange = [
          this.$moment().subtract(30, "days").toDate(),
          this.$moment().toDate(),
        ]
      }else if(range.name == "activity.range_90_days"){
        this.flatpickrRange = [
          this.$moment().subtract(90, "days").toDate(),
          this.$moment().toDate(),
        ]
      }else if(range.name == "activity.range_year"){
        this.flatpickrRange = [
          this.$moment().subtract(365, "days").toDate(),
          this.$moment().toDate(),
        ]
      } else {
          this.flatpickrRange = this.lastRange
      }
      this.lastRange = this.flatpickrRange

      this.initActivityRange(this.flatpickrRange);
    },

    setFilter(filter) {
      this.selectedFilter = filter;
      this.selectedFilterValue = '';
      if (this.selectedFilter.key == "all") {
        this.activityFilterBoxDisable = false;
        this.initActivity();
      }else{
        this.activityFilterBoxDisable = true;
      }
    },

    getActivityTypes() {
      switch (this.mode) {
        case 0:
          return activityAllTypes();
        case 1:
          return activityEventTypes();
        case 2:
          return activityAdminTypes();
      }
    },

    clearExportActivity() {
        this.exportState = EXPORT_STATE_NONE
        this.exportError = ""
        this.exportLink = ""
    },

    getDatePeriod() {
        try {
            return this.selectedRange.value[0].toLocaleDateString('sv-se') + " - " + this.selectedRange.value[1].toLocaleDateString('sv-se')
        }
        catch (e) {
            return ""
        }
    },

    getExportFileName() {
        let datePeriod = this.getDatePeriod()

        if (this.selectedFilterValue === '0')
            return this.$t('activity.report_filter_all', {
                datePeriod: datePeriod
            })

        switch (this.selectedFilter.key) {
            case "KeyId": {
                return this.$t('activity.report_filter_key', {
                    datePeriod: datePeriod
                })
            }
            case "DeviceId": {
                return this.$t('activity.report_filter_door', {
                    datePeriod: datePeriod
                })
            }
            case "UserId": {
                return this.$t('activity.report_filter_user', {
                    datePeriod: datePeriod
                })
            }
            case "PhoneNumber": {
                return this.$t('activity.report_filter_phone', {
                    datePeriod: datePeriod
                })
            }
            default:
                return this.$t('activity.report_filter_all', {
                    datePeriod: datePeriod
                })
        }
    },

    async exportActivity() {
      this.clearExportActivity()
      try {
          let fileName = this.getExportFileName()
          let params = this.buildActivityParams();
          params.IgnorePagination = true;
          params.FileName = btoa(fileName)
          let response = await aggregatedController.getExportActivityLog(params, ACTIVITY_LOG_EXPORT)

          if (response?.status === 200 && response?.data?.ReportId) {
            this.exportState = EXPORT_STATE_WORKING

            let cntr = 0;
            if (this.exportInterval)
              clearInterval(this.exportInterval)

            this.exportInterval = setInterval(async () => {
              let actStatus = await activityController.getExportActivityStatus(response?.data?.ReportId)

              if (actStatus?.data?.State === 2) {
                clearInterval(this.exportInterval)
                this.exportInterval = null

                if (actStatus?.data?.FilePath) {
                  this.exportState = EXPORT_STATE_DONE
                  this.exportLink = actStatus?.data?.FilePath
                  setTimeout(async () => {
                    this.$refs.exportFileLink.$el.click()
                  }, 1000);
                } else {
                  this.setExportError(this.$t('activity.error_no_file'))
                }
              }
              if (actStatus?.data?.State === 3) {
                this.setExportError(this.$t('activity.error_calc'))
              }
              if (cntr++ > 200) {
                this.setExportError(this.$t('activity.error_timeout'))
              }
           }, 3000);
          } else {
            this.setExportError(this.$t('activity.error_server'))
            console.log(response)
          }
      } catch(error) {
        this.setExportError(this.$t('activity.error_internal_exception'))
        console.log(error)
      }
    },

    applyFilter(item) {
      this.$nextTick(() => {
        this.initActivity();
      });
    },
  },
  components: {
    "zs-activity-main-timeline": MainTimeline,
    "empty-list": EmptyList,
    flatPickr,
    Select2,
  },
};
</script>
<style scoped>
.b-border {
  border-bottom: 1px solid #edf2f9;
  padding-bottom: 0px;
}
.select2-dropdown .dropdown-menu .select-dropdown{
  left: -32px !important;
}
.select2-search__field {
    width: 100% !important;
    padding: 0.125rem 2.5rem !important;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35' height='16' opacity='0.5' class='bi bi-search' style='margin-left: 2px' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat left;
}
li.select2-search--inline {
    width: 100% !important;
}

.list-group-item:last-child{
  overflow-y: hidden !important;
}
</style>
